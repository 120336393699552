/* eslint-disable react/jsx-no-literals */

import Header from 'components/Header'
import Thirdparty from 'components/service/Thirdparty'
import { publicConfig } from 'config'
import { getds } from 'ds'
import dynamic from 'next/dynamic'
import { useCallback, useEffect } from 'react'
import ContentBackground from 'shared-components/ad/ContentBackground'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { ViafouraAPIContext } from 'shared-components/contexts/ViafouraAPIContext'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import { defaultAdInferePath } from 'shared-components/hooks/use-ad-path'
import { useIsPreview } from 'shared-components/hooks/use-is-preview'
import Footer from 'shared-components/layout/Footer'
import PageTransition from 'shared-components/layout/PageTransition'
import PopupWrapper from 'shared-components/layout/PopupWrapper'
import UnderNavBlock from 'shared-components/layout/UnderNavBlock'
import AdPath from 'shared-components/service/AdPath'
import styleUtils from 'shared-components/styles/util.module.css'
import Logo from 'shared-svg/AuthorityMedia.svg'
import { DSGetDealBar } from 'types.sg.ds'
import styles from './Layout.module.css'
import Main from 'shared-components/layout/Main'
import { SearchContext } from 'shared-components/contexts/SearchContext'
import { DSLayoutProps } from 'shared-definitions/types'

const AdminPanel = dynamic(() => import('shared-components/layout/AdminPanel'), { ssr: false })
const NotificationsLayer = dynamic(() => import('shared-components/layout/NotificationsLayer'))

const Layout: React.FC<React.PropsWithChildren & { layoutProps: DSLayoutProps }> = ({
  children,
  layoutProps,
}) => {
  const isPreview = useIsPreview()
  const { queue } = NotificationsContext.useContainer()
  const { ready, theme } = ThemeContext.useContainer()
  const { showAdminPanel } = CommonDataContext.useContainer()

  useEffect(() => {
    if (!ready) {
      return
    }

    document.body.classList[theme === 'dark' ? 'add' : 'remove'](styleUtils.darkTheme)
  }, [ready, theme])

  const getDealbar = useCallback(async (...args: Parameters<DSGetDealBar>) => {
    const dataSource = await getds()
    return dataSource.getDealBar(...args)
  }, [])
  return (
    <PlaywireContext.Provider
      initialState={{
        inst: null,
        enabled: !publicConfig('adsDisabled'),
        adClassList: publicConfig('adClassList'),
      }}
    >
      <SearchContext.Provider
        initialState={{
          search: async (...args) => {
            const dataSource = await getds()
            return dataSource.getSearchPage(...args)
          },
        }}
      >
        <ViafouraAPIContext.Provider initialState={{ siteId: publicConfig('viafouraSiteId') }}>
          <UIVariantContext.Provider
            initialState={{ postPreviewScore: 'ring', buttonStyle: 'outline' }}
          >
            <DealBarContext.Provider initialState={{ getDealbar, dealbar: null }}>
              <AdPath
                inferePath={(path, tags) => {
                  if (path === '/') {
                    return '/'
                  }
                  return defaultAdInferePath(path, tags)
                }}
              />
              {showAdminPanel ? <AdminPanel editPostTpl={publicConfig('postEditLink')} /> : null}
              <Header />
              <UnderNavBlock tocVisibility={layoutProps?.tocVisibility} />
              <ContentBackground />
              <Main className={styles.main}>{children}</Main>
              <Footer logo={Logo} logoLink={{ href: publicConfig('authorityMediaLink') }} />
              <PopupWrapper />
              <PageTransition />
              {queue.length ? <NotificationsLayer /> : null}
              {!isPreview ? <Thirdparty /> : null}
            </DealBarContext.Provider>
          </UIVariantContext.Provider>
        </ViafouraAPIContext.Provider>
      </SearchContext.Provider>
    </PlaywireContext.Provider>
  )
}

export default Layout
